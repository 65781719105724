import eventModule from '../../../services/event';
import FiltersMixin from '../../../mixins/filtersMixins';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
const _ = require('lodash');

export default {
  name: 'campaignEventFilter',
  props: {
    selectedOnActionEvent: Object | String,
    readOnly: Boolean,
    channelType: String
  },
  components: {
    BeatLoader
  },
  mixins: [FiltersMixin],
  data() {
    return {
      fetchingData: true,

      eventList: null,
      selectedEvent: {
        eventName: null,
        properties: [],
        matchAnyFilter: false,
        filters: []
      },
      currentFocusEvent: {
        eventName: null,
        properties: [],
        matchAnyFilter: false,
        filters: []
      },
      eventMetadata: null,

      grwEventPropList: []
    };
  },

  methods: {
    //#region ----------------------------- Query Builder methods --------------------

    getFilters() {
      if (this.selectedEvent.eventName) {
        let onActionEventFilter = {
          eventName: this.selectedEvent.eventName,
          matchAnyFilter: this.selectedEvent.matchAnyFilter,
          filters: []
        };

        for (let i = 0; i < this.selectedEvent.filters.length; i++) {
          let temp = this.selectedEvent.filters[i];

          if (temp.propertyName && temp.value) {
            onActionEventFilter.filters.push({
              propertyName: temp.propertyName,
              comparisonType: temp.comparisonType,
              value: temp.value
            });
          }
        }

        return onActionEventFilter;
      }
      // TODO: joi validation of filter @jigar

      return null;
    },

    convertFilterToRawFilter() {
      // set into this.selectedEvent
    },

    // Fetch event list from server
    fetchEventList() {
      this.fetchingData = true;

      eventModule
        .getEventList(this)
        .then((result) => {
          let listFromServer = Object.keys(result.data.data.eventList);

          // Build Custom Events
          let customEventsList = [];
          for (let i = 0; i < listFromServer.length; i++) {
            if (this.grwDefaultEventList.indexOf(listFromServer[i]) == -1) {
              customEventsList.push(listFromServer[i]);
            }
          }

          // Buil White-listed events
          let defaultEventsList = [];
          let channelDefaultEvents = this.grwChannelWiseAllowedDefaultEvents[this.channelType];
          if (channelDefaultEvents) {
            for (let i = 0; i < listFromServer.length; i++) {
              if (channelDefaultEvents.indexOf(listFromServer[i]) != -1) {
                defaultEventsList.push(listFromServer[i]);
              }
            }
          }

          this.eventList = [];
          this.eventList.push(
            {
              label: 'Growlytics Events',
              options: defaultEventsList
            },
            {
              label: 'Custom Events',
              options: customEventsList
            }
          );

          // Remove all events which are hard coded growlytics events.
          this.fetchingData = false;
          this.eventMetadata = result.data.data.eventList;
          this.grwEventPropList = result.data.data.sessionPropList;
          // Set selected event if theirs any
          this.setSelectedOnActionEvent();
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    },

    setSelectedOnActionEvent() {
      if (!this.selectedOnActionEvent) {
        return;
      }
      this.selectedEvent = {
        eventName: this.selectedOnActionEvent.eventName,
        properties: this.eventMetadata[this.selectedOnActionEvent.eventName],
        matchAnyFilter: this.selectedOnActionEvent.matchAnyFilter ? this.selectedOnActionEvent.matchAnyFilter : false,
        filters: []
      };

      this.onEventSelect();
      let propertyList = [];
      for (let i = 0; i < this.selectedEvent.properties.length; i++) {
        propertyList = propertyList.concat(this.selectedEvent.properties[i].options);
      }

      let selectedFilters = this.selectedOnActionEvent.filters;
      for (let i = 0; i < selectedFilters.length; i++) {
        let temp = {
          propertyName: selectedFilters[i].propertyName,
          propertyInfo: null,
          comparisionTypeList: null,
          comparisonType: selectedFilters[i].comparisonType,
          // comparisonTypeLabel: selectedFilters[i].comparisonTypeLabel,
          value: selectedFilters[i].value
        };

        temp.propertyInfo = _.find(propertyList, (o) => {
          return o.name == selectedFilters[i].propertyName;
        });

        // Read property's datatype
        temp.comparisionTypeList = this.getComparisonsForDatatype(temp.propertyInfo.type);
        this.selectedEvent.filters.push(temp);
      }
    },

    getDisplayText(comparisonType, comparisionTypeList) {
      let data = _.find(comparisionTypeList, (c) => {
        return c.key === comparisonType;
      });
      return data.label;
    },

    onEventSelect() {
      let eventPropertyList = [
        {
          label: 'Growlytics Event Properties',
          options: this.grwEventPropList
        }
      ];
      eventPropertyList.push({
        label: 'Event Properties',
        options: this.eventMetadata[this.selectedEvent.eventName]
      });

      // White-list following default events.

      if (this.grwDefaultEventList.indexOf(this.selectedEvent.eventName) !== -1) {
        eventPropertyList.splice(0, 1);
      }

      this.selectedEvent.properties = eventPropertyList;
      this.selectedEvent.filters = [];
    },

    // set eventname on input focus
    setCurrentEventData(eventName, filter) {
      this.currentFocusEvent.eventName = eventName;
      this.currentFocusEvent.property = filter.propertyInfo.name;
    },

    // ON +where button click
    addPropertyFilter() {
      this.selectedEvent.filters.push({
        propertyName: null,
        propertyInfo: null,
        comparisionTypeList: null,
        comparisonType: null,
        value: null
      });
    },

    // delete event from selectedEvent
    // deleteEventFilter() {
    //   this.selectedEvent = {
    //     eventName: null,
    //     properties: [],
    //     filters: []
    //   };
    // },

    // ON property dropdown change
    onFilterPropertyChange(subFilter, propertyGroupList) {
      let propertyList = [];
      for (let i = 0; i < propertyGroupList.length; i++) {
        propertyList = propertyList.concat(propertyGroupList[i].options);
      }

      // Read proeprty object from property name
      subFilter.propertyInfo = _.find(propertyList, (o) => {
        return o.name == subFilter.propertyName;
      });

      // Read property's datatype
      let propertyType = subFilter.propertyInfo.type;
      console.log('data type', propertyType);
      subFilter.comparisionTypeList = this.getComparisonsForDatatype(propertyType);
      subFilter.comparisonType = subFilter.comparisionTypeList[2].key;
    },

    // delete property filter from selectedEvent.filters
    deletePropertyFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    addPropertyGroupBy(eventFilter) {
      eventFilter.groupByList.push({
        propertyName: null
      });
    },

    // delete property filter from selectedEvent.filters
    deletePropertyGroupBy(groupByList, index) {
      groupByList.splice(index, 1);
    },

    //#endregion ----------------------------- Query Builder methods --------------------

    // Fetch event property suggestions
    fetchEventPropertySuggestion(queryString, cb) {
      let params = {
        eventName: this.currentFocusEvent.eventName,
        property: this.currentFocusEvent.property,
        queryString: queryString
      };
      eventModule
        .fetchCustomEventValueSuggetionsList(params)
        .then((result) => {
          if (result.data.success) {
            var links = result.data.data;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
          }
        })
        .catch((e) => {
          cb();
          this.reportError(e);
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    clearFilters() {
      this.selectedEvent = {
        eventName: null,
        properties: [],
        filters: []
      };
    }
  },
  mounted() {
    this.fetchEventList();
  }
};
